<template>
    <div>
        <section class="advanced-port-home">
            <div class="container">
                <h1 class="advanced-port-title">Explore Our Advanced Portfolio</h1>
            </div>
        </section>
        <div class="container row contacts-content">
            <div class="column">
                <p>Our advanced portfolio includes exclusive projects we cannot display publicly but can share privately. Please fill out the form below to gain access.</p>
                <p>You'll find a range of projects, including AI, ML, ultra-secure applications, turn-key platforms, innovative e-commerce, and more.</p>
                <p>Since this information is not available publicly, we kindly ask you to share your reason for requesting access.</p>
                <p>We’re confident you’ll discover something relevant to your needs. If not, we’ll offer <strong style="white-space: nowrap;">8 hours of free</strong> development, consulting, or analytics!</p>
                <div class="advanced-port-afterform">
                    <ContactsButton
                        :desc="buttonText"
                        :id="btnModalId"
                        :formMessage="formMessage"
                        size="large"
                        btn-content="Claim Your Free 8 Hours"
                    />
                </div>
            </div>
            <div class="column contacts-form advanced-port-home-container">
                <AsvancedPortfolioForm @onFormSubmission="onFormSubmission">
                    <div class="contacts-contact-form-header" v-if="submission.isSent" :class="submission.isSent ? 'modal' : ''">
                        <button class="modal-close" @click="close()" aria-label="Close">
                            <img v-svg-inline :data-src="require('@/assets/icons/close-modal.svg')" alt="" />
                        </button>
                        <div class="contacts-contact-form-description" :class="{
                            error: submission.hasError,
                            success: !submission.hasError
                        }">
                            {{ submission.messageText }}
                        </div>
                    </div>
                </AsvancedPortfolioForm>
            </div>
        </div>
    </div>

</template>
<script>
import AsvancedPortfolioForm from "../components/Form/AsvancedPortfolioForm.vue";
import ContactsButton from "../components/ContactsButton.vue";

export default {
    data() {
        return {
            submission: {},
            buttonText:'Contact us directly with your requirements and we will show you why we are always/usually ahead of the game.',
            formMessage: 'I’ve reviewed your portfolio at advanced.wdevs.com and slides.wdevs.com but couldn’t find anything that matches what I’m looking to build. Could you let me know if you’ve worked on something similar to...'
        }
    },
    components: {
        AsvancedPortfolioForm,
        ContactsButton,
    },
    methods: {
        close() {
            this.submission.isSent = false
        },
        getDefaultSubmission() {
            return {
                isSent: false,
                hasError: false,
                messageText: ''
            }
        },
        onFormSubmission(payload) {
            Object.assign(this.submission, this.getDefaultSubmission(), payload);
        }
    }
}
</script>

<style lang="scss">
@import "../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.advanced-port-home {
    padding: 60px 0;

    @include media('>=desktop') {
        padding: 120px 0;
    }

    @include media('>=widescreen') {
        padding: 240px 0 120px;
    }
}

.advanced-port-title {
    text-transform: uppercase;
    color: $black;
    line-height: 111%;
    margin: 0;
    font-size: 34px;

    @include media('>=tablet') {
        font-size: 50px;
    }

    @include media('>=tablet-xl') {
        font-size: 60px;
    }

    @include media('>=desktop') {
        font-size: 72px;
    }
}

.advanced-port-home-container {
    a {
        color: $red;
    }

    p {
        margin-bottom: 32px;
    }

    .button {
        margin-top: 8px;
    }

    .form {
        max-height: 100%;
    }

    .input-group {
        .placeholder {
            right: 11px;
        }

        textarea {
            height: 158px;

            @include media("<phone") {
                height: 180px;
            }

            @include media(">=tablet") {
                height: 132px;
            }

            @include media(">=widescreen") {
                height: 132px;
            }
        }
    }

}

.advanced-port-afterform {
    margin: 40px auto 0;
    font-size: 16px;
    font-style: italic;
    color: $base-text-color;

    @include media(">=tablet") {
        font-size: 20px;
    }
}
</style>
